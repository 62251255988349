import React from "react";
//
import "./footer.scss";
import Menu from "components/menu/menu";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useMenu } from "hooks/useMenus.query";
import classNames from "classnames";

import Logo from "assets/botschaft-logo.svg";
import { legalMenuDE, legalMenuEN } from "data/menu.data";

interface Props {
  pageContext: {
    singlePost?: boolean;
  };
  isFrontPage: boolean;
}

const Footer: React.FC<Props> = (props) => {
  const { languages, defaultLanguage, changeLanguage, language } = useI18next();
  const socialMenu = useMenu("FOOTER_SOCIAL", 'de');
  const legalMenu = language === "de" ? legalMenuDE : legalMenuEN;//useMenu("FOOTER_LEGAL", 'language');

  return (
    <div
      className={classNames("footer", { "is-frontpage": props.isFrontPage })}
    >
      <div className="footer__wrapper">
        <div className="footer-top">
          <div className="footer-menu-list">
            {/* FOOTER TOP */}
            <Menu isFooter isFrontPage={props.isFrontPage} />
          </div>
        </div>
        {/* FOOTER BOTTOM */}
        <div className="footer-bottom">
          <div className="footer-bottom-menu-list__container">
               {/* LANGUAGE */}
               <ul className="footer-bottom-menu-list">
                {languages.map((lng) => (
                  <li key={lng}>
                    <a
                      href={`/${lng !== defaultLanguage ? lng + '/' : ""}`}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   changeLanguage(lng);
                      // }}
                    >
                      {lng}
                    </a>
                  </li>
                ))}
              </ul>
            {/* FOOTER SOCIAL */}
            <ul className="footer-bottom-menu-list">
              {!!socialMenu &&
                socialMenu.node.menuItems.nodes.map((item: any, key: any) => (
                  <li key={key}>
                    <a href={item.path} target="_blank">
                      {item.label}
                    </a>
                  </li>
                ))}
            </ul>
              {/* FOOTER LEGAL */}
              <ul className="footer-bottom-menu-list">
                {!!legalMenu &&
                  legalMenu.node.menuItems.nodes.map((item: any, key: any) => (
                    <li key={key}>
                      <a href={item.path} target={item.target}>{item.label}</a>
                    </li>
                  ))}
              </ul>
            </div>

          {/* LOGO */}
          <a href={language === 'en' ? "/en/" : "/"} className="footer-logo">
            <Logo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
