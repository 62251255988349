// https://www.wpgraphql.com/docs/menus/

import { useStaticQuery, graphql } from 'gatsby';

export const useOptions = () => {
  const data = useStaticQuery(
    graphql`
      query ThemeOptions {
        wp {
          themeGeneralSettings {
            popup {
                active 
                imageDe { 
                  sourceUrl
                } 
                imageEn { 
                  sourceUrl
                }
              }
            }
        }
      }
    `
  );
  return data;
};
