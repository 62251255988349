export const mainMenuDE = {
  node: {
    menuItems: {
      nodes: [
        {
          label: "About",
          path: "/about/",
        },
        {
          label: "Location & Veranstaltungen",
          path: "/location-und-veranstaltungen/",
        },
        {
          label: "Speisekarte",
          path: "/speisekarte/",
        },
        {
          label: "Reservierung",
          path: "/reservierung/",
        },
        {
          label: "Kontakt",
          path: "/contact/",
        },
        {
          label: "News",
          path: "/news/",
        },
      ],
    },
  },
};

export const mainMenuEN = {
  node: {
    menuItems: {
      nodes: [
        {
          label: "About",
          path: "/en/about/",
        },
        {
          label: "Location & Events",
          path: "/en/location-and-events/",
        },
        {
          label: "Menu",
          path: "/en/menu/",
        },
        {
          label: "Reservations",
          path: "/en/reservierung/",
        },
        {
          label: "Contact",
          path: "/en/contact/",
        },
        {
          label: "News",
          path: "/en/news/",
        },
      ],
    },
  },
};

export const legalMenuDE = {
  node: {
    menuItems: {
      nodes: [
        {
          label: "Newsletter",
          path: "http://eepurl.com/hmip7j",
          target: "_blank",
        },
        {
          label: "Impressum/Datenschutz",
          path: "/impressum/",
          target: "_self"
        },
      ],
    },
  },
};


export const legalMenuEN = {
  node: {
    menuItems: {
      nodes: [
        {
          label: "Newsletter",
          path: "http://eepurl.com/hmip7j",
          target: "_blank",
        },
        {
          label: "Imprint/Privacy",
          path: "/en/imprint/",
          target: "_self"
        },
      ],
    },
  },
};
