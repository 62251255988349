// https://www.wpgraphql.com/docs/menus/

import { useStaticQuery, graphql } from "gatsby";

export const useMenu = (location: string, language: string) => {
  const data = useStaticQuery(
    graphql`
      query AllMenus {
        allWpMenu {
          edges {
            node {
              name
              locations
              language
              menuItems {
                nodes {
                  id
                  label
                  path
                  target
                  cssClasses
                  parentId
                  connectedNode {
                    node {
                      ... on WpPage {
                        isPostsPage
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const menu = data.allWpMenu.edges.find(
    (n: any) =>
      n.node.locations.includes(location) && n.node.language === language
  );

  return menu;
};
