import classNames from "classnames";
import Hamburger from "components/hamburger/hamburger";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import "./popup.scss";
interface Props {
  imageDe: {
    sourceUrl: string;
  };
  imageEn: {
    sourceUrl: string;
  };
  onClose: Function;
}

const Popup: React.FC<Props> = (props) => {
  const { language } = useI18next();

  return (
    <div className="popup">
      <div
        className="popup-fakeBackground"
        onClick={() => props.onClose()}
      ></div>
      <div className="popup-inner popup-wrapper">
        <div className="popup-content">
          <Hamburger
            isOpen={true}
            className="popup-cross"
            onClick={() => props.onClose()}
          />
          <img
            src={
              language === "de"
                ? props.imageDe.sourceUrl
                : props.imageEn.sourceUrl
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
