import React, { useState, useEffect } from "react";
import Hamburger from "components/hamburger/hamburger";
import classNames from "classnames";
import AniLink from "gatsby-plugin-transition-link/AniLink";

//
import "./header.scss";
import { useI18next } from "gatsby-plugin-react-i18next";

export interface Props {
  isOpen: boolean;
  onMenuClick: (isOpen: boolean) => void;
  isFrontPage: boolean;
}

const Header: React.FC<Props> = (props) => {
  const [solid, setSolid] = useState<boolean>(false);
  const { language } = useI18next();


  useEffect(() => {
    //navbar scroll changeBackground function
    const changeBackground = () => {
      const offset = !!document.getElementsByClassName("hero").length
        ? window.innerHeight
        : 0;
      if (window.scrollY >= offset) {
        setSolid(true);
      } else {
        setSolid(false);
      }
    };
    // adding the event when scroll change background
    changeBackground();
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <div
      className={classNames(
        "header",
        { "is-active": solid },
        { "is-frontpage": props.isFrontPage }
      )}
    >
      <div className="header-wrapper">
        <AniLink
          duration={2}
          fade
          hex="#BDBDBC"
          bg="#BDBDBC"
          direction="top"
          to={language === 'en' ? "/en/" : "/"}
          className="header-headline"
        >
          FRANKFURTER BOTSCHAFT
        </AniLink>
        {/* TOGGLE */}
        <Hamburger
          className="header__button"
          isOpen={props.isOpen}
          onClick={() => props.onMenuClick(!props.isOpen)}
        />
      </div>
    </div>
  );
};

export default Header;
