import React from "react";
import classNames from "classnames";

import "./hamburger.scss";

interface Props {
  className?: string;
  isOpen: boolean;
  onClick: () => void;
}

const Hamburger: React.FC<Props> = (props) => {
  return (
    <button
      className={classNames(props.className, "hamburger", "hamburger--slider", {
        "is-active": props.isOpen,
      })}
      onClick={props.onClick}
    >
      <span className="hamburger-inner"></span>
    </button>
  );
};

export default Hamburger;
