import React, { useState } from "react";
import "./menu.scss";
import classNames from "classnames";
import { useMenu } from "hooks/useMenus.query";
import { useI18next } from "gatsby-plugin-react-i18next";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { mainMenuDE, mainMenuEN } from "data/menu.data";

interface Props {
  isFooter?: boolean;
  isFrontPage?: boolean;
}

const Menu: React.FC<Props> = (props) => {
  const { language } = useI18next();
  // const mainMenu = useMenu("MAIN_MENU", language);
  const mainMenu = language === "de" ? mainMenuDE : mainMenuEN;
  return (
    <div
      className={classNames("menu", {
        "is-footer": props.isFooter,
        "is-frontpage": props.isFrontPage,
      })}
    >
      <ul className="menu__list">
        {!!mainMenu &&
          mainMenu.node.menuItems.nodes.map((item: any, key: any) => {
            return (
              <li className="menu-item" key={key}>
                <AniLink
                  duration={2}
                  fade
                  hex="#BDBDBC"
                  bg="#BDBDBC"
                  direction="top"
                  className="menu-item__link"
                  to={item.path}
                  activeClassName="is-active"
                >
                  <span className="is-default">{item.label}</span>
                  <span className="is-serif">{item.label}</span>
                </AniLink>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Menu;
