import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./navOverlay.scss";
import Menu from "components/menu/menu";

interface Props {
  isFrontPage: boolean;
  isOpen: boolean;
}

const NavOverlay: React.FC<Props> = (props) => {
  const isOpenOverlay = props.isFrontPage || props.isOpen;

  return (
    <div
      className={classNames("nav-overlay", {
        "is-open": props.isOpen || props.isFrontPage,
        "is-frontpage": props.isFrontPage
      })}
    >
      <div className="nav-overlay-bg" />
      <Menu isFrontPage={props.isFrontPage} />
    </div>
  );
};

export default NavOverlay;
